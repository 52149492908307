//
// pagination.scss
//
.page-link {
    i {
        vertical-align: middle;
    }
}

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px;
        border: none;
        // display: inline;
    }
}