//
// app-chat.scss
//

// chat
.chat-search {
    display: table;
    width: 100%;
    padding-bottom: $spacer;

    .chat-search-box {
        display: table-cell;
        vertical-align: middle;
        position: relative;

        .search-icon {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
        }
    }

    .form-control {
        padding-right: 2rem;
    }
}

.chat-conversation-list {
    list-style: none;

    li {
        margin-bottom: 20px;
        position: relative;

        .chat-conversation-text {
            margin-bottom: $spacer * 0.25;

            &:first-child {
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    border: solid transparent;
                    height: 0;
                    width: 0;
                    border-top-color: var(--#{$prefix}tertiary-bg);
                    border-width: 6px;
                    margin-right: -1px;
                    border-right-color: var(--#{$prefix}tertiary-bg);
                }
            }

            &:hover {
                .chat-conversation-actions {
                    // display: flex;
                    opacity: 1;
                    align-items: top;
                }
            }
        }

        .chat-conversation-actions {
            // display: none;
            opacity: 0;
            float: right;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .chat-ctext-wrap {
        background: var(--#{$prefix}tertiary-bg);
        border-radius: 0 $border-radius-sm $border-radius-sm;
        display: inline-block;
        padding: $input-padding-y $input-padding-x;
        position: relative;
        max-width: 750px;
        margin-left: 10px;

        i {
            display: block;
            font-size: $h6-font-size;
            font-style: normal;
            font-weight: bold;
            position: relative;
        }

        p {
            margin: 0;
            padding: 0;
        }
    }

    .odd {
        .chat-conversation-text {
            float: right !important;
            text-align: right !important;
            margin-right: 10px;

            &:first-child {
                &::before {
                    border-color: transparent;
                    border-left-color: $primary;
                    border-top-color: $primary;
                    right: 3px;
                    left: auto;
                }
            }
        }

        .chat-ctext-wrap {
            background-color: $primary;
            color: $white;
            border-radius: $border-radius-sm 0 $border-radius-sm $border-radius-sm;
        }
    }
}

.chat-user-status-box {
    position: relative;
    display: flex;
    justify-content: center;

    span {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 2px;
            height: 11px;
            width: 11px;
            background-color: $success;
            border: 2px solid $border-color;
            border-radius: 50%;
        }
    }
}

// video call
.video-call {
    background-image: url("../images/users/chat.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    position: relative;

    .video-call-head {
        img {
            height: 4rem;
            width: 4rem;
        }
    }

    .video-call-action {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        padding: 60px 0 16px;
        background: linear-gradient(to top, $black 0, rgba($black, 0) 90%);
    }
}

// voice call
.voice-call {
    height: 450px;
    position: relative;

    .voice-call-head {
        img {
            width: 6rem;
            height: 6rem;
        }
    }

    .voice-call-action {
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
    }
}

// setting
.app-chat-setting {

    .accordion-button,
    .accordion-body {
        background-color: $card-bg  !important;
    }
}

.chatbox-height {
    max-height: calc(100vh - 362px);
}

.chat-setting-height {
    max-height: calc(100vh - 486px);
}