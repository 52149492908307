//
// card.scss
//

.card {
    box-shadow: $card-box-shadow;
    margin-bottom: $spacer;
}

.card-header {
    border-bottom: 1px solid var(--bs-card-border-color);
}

.card-link+.card-link {
    margin-left: $card-spacer-x;
    margin-right: 0;
}

.card-drop {
    font-size: $h4-font-size;
    line-height: 0;
    color: inherit;
}

.card-height-100 {
    height: calc(100% - #{$spacer});
}

.card-widgets {
    float: right;
    height: 16px;

    >a {
        color: inherit;
        font-size: $h4-font-size;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &::before {
                    content: "\ebc0";
                }
            }
        }
    }
}

// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

// Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba(var(--#{$prefix}dark-rgb), 0.5);
    cursor: progress;

    .card-portlets-loader {
        background-color: var(--#{$prefix}dark);
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 3px;
        margin-left: -($spacer * 0.5);
        margin-top: -($spacer * 0.5);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}


.card-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-semibold;
    margin: 0;
}

.sub-header {
    font-size: $font-size-base;
    margin-bottom: $spacer;
    color: $text-muted;
}


.card-border {
    .card {
        border-color: var(--#{$prefix}card-border-color);
    }
}