//
// nav.scss
//

.nav-link {
    &.active {
        color: $primary;
    }
}

.nav-tabs {

    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            border-color: transparent !important;
            // padding: 0.375rem 1rem;
        }

        a.active {
            border-bottom: $border-width solid $primary !important;
            margin-bottom: 1px;
        }
    }
}

// pills
.nav-tabs,
.nav-pills {
    >li {
        >a {
            font-weight: $font-weight-semibold;
        }
    }
}

.nav-pills {
    background: var(--#{$prefix}tertiary-bg);
    border-radius: $border-radius-sm;

    .nav-link {
        background-color: transparent;
        color: var(--#{$prefix}link-color);
    }

    .nav-link.active,
    .show>.nav-link {
        color: $white;
        background-color: $primary;
        box-shadow: $box-shadow-sm;
    }
}

.tab-content {
    padding: 20px 0 0 0;
}


.icon-wizard {
    .nav-item {
        .nav-link {
            padding: 11px 14px !important;
            box-shadow: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 3px;
            border-radius: var(--#{$prefix}border-radius) !important;
        }
    }
}