//
// apexcharts.scss
//

.apex-charts {
    min-height: 10px !important;

    text {
        font-family: $font-family-base !important;
        font-weight: $font-weight-medium;
        fill: $headings-color;
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }

    .apexcharts-title-text {
        fill: $headings-color;
    }
}


.apexcharts-bar-series {
    path {
        stroke: $border-color;
    }
}

.apexcharts-xaxis-tick {
    fill: $border-color;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: var(--#{$prefix}light);
}

.apexcharts-legend-text {
    color: $text-muted !important;
}



.apexcharts-grid-row {
    fill: $input-bg;
}


.apexcharts-pie-area {
    stroke: $border-color;
}

.apexcharts-tracks {
    .apexcharts-radialbar-track {
        path {
            stroke: var(--#{$prefix}light);
        }
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-tooltip {
    border: 1px solid var(--#{$prefix}border-color) !important;
    background-color: $card-bg !important;
    box-shadow: $box-shadow !important;

    * {
        font-family: $font-family-base !important;
        color: var(--#{$prefix}body-color) !important;
    }

    .apexcharts-tooltip-title {
        background-color: rgba(var(--#{$prefix}light-rgb), .75) !important;
        border-bottom: 1px solid var(--#{$prefix}border-color) !important;
    }
}

.apexcharts-tooltip.apexcharts-theme-dark {
    * {
        color: $white !important;
    }
}

.apexcharts-legend-series {
    font-weight: $font-weight-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: rgba(var(--#{$prefix}light-rgb), .75);
}

.apexcharts-legend-text {
    color: var(--#{$prefix}body-color) !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
    fill: var(--#{$prefix}link-color);
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: var(--#{$prefix}link-color);
    }
}

.apexcharts-yaxis-title {
    font-weight: 500;
}

// column dynamic loaded chart

#dynamicloadedchart-wrap {
    margin: 0px auto;
    max-width: 800px;
    position: relative;
}

.chart-box {
    padding-left: 0;
}

#chart-year,
#chart-quarter {
    width: 96%;
    max-width: 48%;
    box-shadow: none;
    padding-left: 0;
    padding-top: 20px;
    background: $card-bg;
    border: 1px solid $border-color;
}

#chart-year {
    float: left;
    position: relative;
    transition: 1s ease transform;
    z-index: 3;

    &.chart-quarter-activated {
        transform: translateX(0);
        transition: 1s ease transform;
    }
}

#chart-quarter {
    float: left;
    position: relative;
    z-index: -2;
    transition: 1s ease transform;

    &.active {
        transition: 1.1s ease-in-out transform;
        transform: translateX(0);
        z-index: 1;
    }
}

@media screen and (min-width: 480px) {
    #chart-year {
        transform: translateX(50%);
    }

    #chart-quarter {
        transform: translateX(-50%);
    }
}

// radar chart

.apexcharts-radar-series {

    polygon,
    line {
        stroke: $border-color;
    }
}

.apexcharts-pie {

    circle,
    line {
        stroke: $border-color;
    }

    text {
        fill: var(--#{$prefix}body-color);
    }
}