// 
// _light-mode.scss
// 


/* Root Prefix Variable */
:root {
    --#{$prefix}logo-lg-height:                24px;
    --#{$prefix}logo-sm-height:                26px;

    --#{$prefix}main-nav-width:               280px;
    --#{$prefix}main-nav-width-sm:             75px; 
    --#{$prefix}main-nav-item-icon-size:       22px;
    --#{$prefix}main-nav-item-font-size:       15px;
    --#{$prefix}main-nav-item-padding-x:       25px;
    --#{$prefix}main-nav-item-padding-y:       10px;

    --#{$prefix}topbar-height:                 100px;
    --#{$prefix}footer-height:                 60px;
    
    // Heading Variable
    --#{$prefix}headings-color:                            #313b5e;

    --#{$prefix}input-border-color:             #d8dfe7;
    --#{$prefix}input-focus-border-color:       #b0b0bb;
}


/* Color system - Dark Mode only */
html[data-bs-theme="dark"], [data-bs-theme="dark"] {

    --#{$prefix}border-color:                   #2f3944;

    --#{$prefix}light:                     #{$body-tertiary-bg-dark};
    --#{$prefix}dark:                      #{$body-tertiary-color-dark};
    
    --#{$prefix}light-rgb:                 #{to-rgb($body-tertiary-bg-dark)};
    --#{$prefix}dark-rgb:                  #{to-rgb($body-tertiary-color-dark)};

    // Heading Variable
    --#{$prefix}headings-color:            #{$body-color-dark};

    --#{$prefix}input-border-color:             #3a4551;
    --#{$prefix}input-focus-border-color:       #4a5663;
}


/* Main Menu Light */
html[data-menu-color="light"]{
    --#{$prefix}main-nav-bg:                               #f8f5f1;
    --#{$prefix}main-nav-item-color:                       #797b97;
    --#{$prefix}main-nav-item-hover-bg:                    transparent;
    --#{$prefix}main-nav-item-hover-color:                 #ff6c2f;
    --#{$prefix}main-nav-border-color:                     #eaedf1;
}

/* Main Menu Dark */
html[data-menu-color="dark"],
html[data-bs-theme="dark"][data-menu-color="light"] {
    --#{$prefix}main-nav-bg:                               #262d34;
    --#{$prefix}main-nav-item-color:                       #9097a7;
    --#{$prefix}main-nav-item-hover-bg:                    transparent;
    --#{$prefix}main-nav-item-hover-color:                 #{$white};
    --#{$prefix}main-nav-border-color:                     #2f3944;
}

/* Topbar Light */
html[data-topbar-color="light"] {
    --#{$prefix}topbar-bg:                                 #f9f7f7;
    --#{$prefix}topbar-item-color:                         #707793;
    --#{$prefix}topbar-search-bg:                          #eae8e8;
}

/* Topbar Dark */
html[data-topbar-color="dark"], 
html[data-bs-theme="dark"][data-topbar-color="light"]{
    --#{$prefix}topbar-bg:                                 #22282e;
    --#{$prefix}topbar-item-color:                         #dee2e6;
    --#{$prefix}topbar-search-bg:                          #2c3238;
}